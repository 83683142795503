import Button from "components/Button";
import { createQueryParams } from "lib/helper/url";

const defaultOnClick = (page: number) => {
  const params = { currentPage: page };
  window.location.href = createQueryParams({ params, url: window.location.href });
};

const Paginator = ({
  itemsCount = 0,
  currentPage = 0,
  itemsPerPage = 10,
  pagesCount = 10,
  first = false,
  prev = false,
  next = false,
  last = false,
  onClick = defaultOnClick,
  ...props
}) => {
  itemsCount = Number(itemsCount) || 0;
  currentPage = Number(currentPage) || 0;
  currentPage = currentPage <= 0 ? 0 : currentPage;
  itemsPerPage = Number(itemsPerPage) || 10;
  pagesCount = Number(pagesCount) || pagesCount;

  const firstPage = 0;
  const prevPage = currentPage <= firstPage ? firstPage : currentPage - 1;
  const noOfPages = Math.ceil(itemsCount / itemsPerPage);
  const lastPage = noOfPages - 1;
  const nextPage = currentPage >= lastPage ? lastPage : currentPage + 1;
  const halfStart = Math.floor(pagesCount / 2);
  const pages = noOfPages <= pagesCount ? noOfPages : pagesCount;

  if (noOfPages < 2) return <></>;

  return (
    <div className="Pagination">
      {first ? (
        <Button onClick={() => onClick(firstPage)} disabled={currentPage <= 0} {...props}>
          First
        </Button>
      ) : (
        <></>
      )}
      {prev ? (
        <Button
          className="prev"
          onClick={() => onClick(prevPage)}
          disabled={currentPage <= 0}
          {...props}
        >
          Prev
        </Button>
      ) : (
        <></>
      )}

      {currentPage <= noOfPages ? (
        [...Array(pages)].map((x, i) => {
          const page =
            currentPage < halfStart
              ? i
              : currentPage < lastPage - halfStart
                ? i + (currentPage - halfStart + 1)
                : i + lastPage - pagesCount + 1;
          return (
            <Button
              key={i}
              className={page === currentPage ? "selected" : ""}
              disabled={page === currentPage}
              onClick={() => onClick(page)}
              {...props}
            >
              {page + 1}
            </Button>
          );
        })
      ) : (
        <></>
      )}

      {next ? (
        <Button
          className="next"
          onClick={() => onClick(nextPage)}
          disabled={currentPage >= lastPage}
          {...props}
        >
          Next
        </Button>
      ) : (
        <></>
      )}
      {last ? (
        <Button onClick={() => onClick(lastPage)} disabled={currentPage >= lastPage} {...props}>
          Last
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Paginator;
