import { InputHTMLAttributes, forwardRef } from "react";
import { InputLabel, InputLabelProps } from "components/InputLabel";
import Icon from "components/Icon";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement>, InputLabelProps {
  label?: string;
  placeholder?: string;
  autocomplete?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  defaultValue?: string;
  leftIcon?: string;
}

const TextInput = (
  {
    label,
    style,
    value,
    defaultValue,
    required,
    optionalLabel,
    className,
    leftIcon,
    ...args
  }: TextInputProps,
  ref
) => {
  // if `defaultValue` is set, the component is uncontrolled and therefore should not set a `value`
  const val = defaultValue !== undefined ? undefined : (value ?? "");

  return (
    <div className={`TextInput ${className ?? ""}`} style={style}>
      <InputLabel label={label} required={required} optionalLabel={optionalLabel} />
      <div className="TextInput-wrapper">
        {leftIcon && <Icon icon={leftIcon} className="TextInput-leftIcon" />}
        <input
          ref={ref}
          type="text"
          required={required}
          value={val}
          defaultValue={defaultValue}
          className={leftIcon ? "with-left-icon" : ""}
          {...args}
        />
      </div>
    </div>
  );
};

export default forwardRef(TextInput);
