// WARNING : THIS FILE IS PUBLIC
// DO NOT PUBLISH SECRETS HERE

process.env.TZ = "UTC";

interface Config {
  [key: string]: {
    default: any;
    prod?: any;
    staging?: any;
    local?: any;
    "dev-anthony"?: any;
    "dev-carl"?: any;
    "dev-arnaud"?: any;
  };
}

const configs: Config = {
  max_account_emails: {
    default: 20,
  },
  daily_user_scan_limit: {
    default: 100,
    prod: 5,
  },
  scan_interval_hours: {
    default: 7 * 24,
  },
  scan_duration_ms: {
    default: 15 * 1000,
  },
  user_home_path: {
    default: "/user/data-brokers",
  },
  admin_home_path: {
    default: "/admin/accounts",
  },
  session_expiration_hours: {
    default: 24 * 30,
  },
  admin_session_expiration_hours: {
    default: 24,
  },
  secrets: {
    default: { region: "us-east-1" },
    local: {
      region: "us-east-1",
      endpoint: "http://localhost:4566",
      accessKeyId: "access_key",
      secretAccessKey: "secret_key",
    },
  },
  ironclad: {
    default: {
      accessId: "1ea333df-7a4c-48fe-b9d5-4eb37f7c0845",
      forms: {
        daniels_law: { key: "embedded-njspba-eula", contractId: "220763" },
        regular: { key: "embedded-tucovqfeu", contractId: "223230" },
        florida: { key: "embedded-w1h1q1-oe", contractId: "270396" },
      },
    },
  },
  app_url: {
    default: "",
    local: "http://localhost:3000",
    staging: "https://app.staging.dev-atlas.com",
    prod: "https://app.atlas.net",
    "dev-anthony": "https://app.anthony.dev-atlas.com",
    "dev-carl": "https://app.carl.dev-atlas.com",
    "dev-arnaud": "https://app.arnaud.dev-atlas.com",
  },
  leo_signup_url: {
    default: "",
    local: "http://localhost:3000/signup/leo",
    staging: "https://app.staging.dev-atlas.com/signup/leo",
    prod: "https://leo.atlas.net",
  },
  mixpanel: {
    prod: {
      access_key: "eca297c8f7238303465b9e312c8d88de",
      app_name: "atlas-web",
      is_debug: false,
    },
    staging: {
      access_key: "c59e4cdf21d579eb869b7ad44795ea36",
      app_name: "atlas-web",
      is_debug: false,
    },
    default: {
      access_key: "c59e4cdf21d579eb869b7ad44795ea36",
      app_name: "atlas-web",
      is_debug: true,
    },
  },
  hotjar: {
    default: {
      hjid: 3199622,
      enabled: false,
    },
    staging: {
      hjid: 3199014,
      enabled: true,
    },
    prod: {
      hjid: 3297784,
      enabled: true,
    },
  },
  places_index: {
    default: "atlasv4-local-place-index",
    local: "atlasv4-local-place-index",
  },
  org_pictures_url: {
    default: "https://atlasv4-prod-organization-pictures.s3.amazonaws.com",
    local: "http://localhost:4566/atlasv4-local-organization-pictures",
  },
  posthog: {
    default: {
      enabled: false,
    },
    staging: {
      enabled: false,
    },
    prod: {
      enabled: true,
    },
  },
  rollbar: {
    default: {
      enabled: false,
    },
    staging: {
      enabled: true,
    },
    prod: {
      enabled: true,
    },
  },
  auth: {
    default: {
      admin: {
        passport_google_oauth20: {
          login: {
            apiUrl: "/api/auth/admin/google/login",
            callbackUrl: "/api/auth/admin/google/login/callback",
            authorizationUrl: "/admin/accounts",
            noAuthUrl: "/admin",
          },
        },
      },
      user: {
        passport_google_oauth20: {
          login: {
            apiUrl: "/api/auth/user/google/login",
            callbackUrl: "/api/auth/user/google/login/callback",
            authorizationUrl: "/user/data-brokers",
            noAuthUrl: "/api/auth/user/noauth",
          },
          signup: {
            apiUrl: "/api/auth/user/google/signup",
            callbackUrl: "/api/auth/user/google/signup/callback",
            authorizationUrl: "/onboard",
            noAuthUrl: "/api/auth/user/nosignup",
          },
        },
      },
    },
  },
  datadog: {
    default: {
      applicationId: "0e01262a-edce-433a-9b0d-8968048dd4be",
      clientToken: "pube093abe87a7705414d2d6abb70b70cf4",
      site: "datadoghq.com",
      service: "app",
      defaultPrivacyLevel: "mask-user-input",
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      silentMultipleInit: true,
    },
  },
  chargebee: {
    default: {
      slackNotifications: false,
      enable: true,
      item_price_id: "atlas-premium-USD-Yearly",
      site: "crowdshield-test",
      publishableKey: "test_BViaDwCcuKTCcdUvcuOaT6v2WRzODoEagW8",
      discounts: {
        nj_njspba: {
          retired: "NJSPBA_RETIRED_ULPP",
          active: "NJSPBA_ACTIVE_ULPP",
          inactive: "NJSPBA_INACTIVE_ULPP",
        },
        nj_stfa: {
          retired: "NJ_STFA_RETIRED",
          active: "NJ_STFA_ACTIVE",
          inactive: "NJ_STFA_INACTIVE",
        },
        ny_suffolkpba: {
          active: "NY_SUFFOLKPBA_ACTIVE",
        },
        nj_pba105: {
          retired: "NJSPBA_105_RETIRED",
        },
      },
      planId: "atlas-premium",
    },
    staging: {
      enable: true,
      site: "crowdshield",
      item_price_id: "atlas-premium-staging-USD-Yearly",
      publishableKey: "live_HhZEJq92RMhx2KGs3imbOCpG21A2olCZ",
      discounts: {
        nj_njspba: {
          retired: "NJSPBA_RETIRED_ULPP_STAGING",
          active: "NJSPBA_ACTIVE_ULPP_STAGING",
          inactive: "NJSPBA_INACTIVE_ULPP_STAGING",
        },
        nj_stfa: {
          retired: "NJ_STFA_RETIRED_STAGING",
          active: "NJ_STFA_ACTIVE_STAGING",
          inactive: "NJ_STFA_INACTIVE_STAGING",
        },
        ny_suffolkpba: {
          active: "NY_SUFFOLKPBA_ACTIVE_STAGING",
        },
      },
      planId: "atlas-premium-staging",
    },
    prod: {
      slackNotifications: true,
      enable: true,
      site: "crowdshield",
      item_price_id: "atlas-premium-USD-Yearly",
      publishableKey: "live_HhZEJq92RMhx2KGs3imbOCpG21A2olCZ",
      discounts: {
        nj_njspba: {
          retired: "NJSPBA_RETIRED_ULPP",
          active: "NJSPBA_ACTIVE_ULPP",
          inactive: "NJSPBA_INACTIVE_ULPP",
        },
        nj_stfa: {
          retired: "NJ_STFA_RETIRED",
          active: "NJ_STFA_ACTIVE",
          inactive: "NJ_STFA_INACTIVE",
        },
        ny_suffolkpba: {
          active: "NY_SUFFOLKPBA_ACTIVE",
        },
        nj_pba105: {
          retired: "NJSPBA_105_RETIRED",
        },
      },
      planId: "atlas-premium",
    },
  },
  identityGuard: {
    default: {
      auth: "",
      url: "",
      offerCode: "local",
    },
    staging: {
      auth: process.env.IDENTITY_GUARD_KEY,
      url: "https://staging.api.identityguard.com/company/enrollments",
      offerCode: "jiazlqksvo",
    },
    prod: {
      auth: process.env.IDENTITY_GUARD_KEY,
      url: "https://api.identityguard.com/company/enrollments",
      offerCode: "fotizod3ar",
    },
  },
  segment: {
    default: {
      writeKey: "H3CIBClBY3LvSRYQq5CSGZcZNhTeD046",
      enabled: false,
    },
    staging: {
      writeKey: "H3CIBClBY3LvSRYQq5CSGZcZNhTeD046",
      enabled: true,
    },
    prod: {
      writeKey: "pZgFQrKoA36YPPSj4XO4TWN9qZ4hCxQZ",
      enabled: true,
    },
  },
  transactionalEmailService: {
    default: {
      source: "customerio",
    },
    local: {
      source: "smtp",
    },
  },
  skipTosStep: {
    default: process.env.NEXT_PUBLIC_SKIP_TOS_STEP === "true",
  },
  sms: {
    default: {
      enabled: false,
      campaignId: "cyc0MnvS",
    },
    prod: {
      enabled: true,
      campaignId: "cyc0MnvS",
    },
    staging: {
      enabled: true,
      campaignId: "cyc0MnvS",
    },
  },
  dataIntegrity: {
    default: {
      // atlasbot-testing slack webhook url
      alertWebhookUrl:
        "https://hooks.slack.com/services/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",
    },
    prod: {
      alertWebhookUrl:
        "https://hooks.slack.com/services/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",
    },
  },
  disableSignupsForOrgs: {
    default: true,
  },
  atlasmail_page_size: {
    default: 10,
  },
  etlSheetsExists: {
    prod: true,
    default: false,
  },
  replyEmail: {
    default: "support@atlas.net",
  },
};

type Environment = "local" | "staging" | "prod";

// HACK PLEASE UNDO THIS
const hostname = typeof window !== "undefined" ? window.location.hostname : "";
export const env: Environment =
  hostname === "app.atlas.net"
    ? "prod"
    : hostname.endsWith(".dev-atlas.com")
      ? (hostname.split(".")[1] as Environment)
      : hostname === "localhost"
        ? "local"
        : ((process.env.NEXT_PUBLIC_ENV || "local") as Environment);

export const config = Object.fromEntries(
  Object.entries(configs).map(([key, value]) => [
    key,
    value[env] ? value[env] : (value as any).default, // cypress has issues using nullish collasceing operator with Es2022 as the target
  ])
);
